<template>
    <div class="overlay">
        <div class="p-5 bg-primary text-white rounded" id="serviesPage">
        </div>
    </div>
    <main class="container">
        <h1 class="text-center my-5" id="servicesTitle">Notre Mission</h1>
        <div class="row">
            <div class="col-md-6">
                <p class="">
                    <strong>À Le Port</strong>
                    La fracture numérique représente un défi majeur qui affecte une partie
                    significative de notre population. Notre mission est d'œuvrer pour réduire cette fracture en
                    facilitant l'accès
                    à des services informatiques et l'utilisation des outils numériques pour tous,
                    en particulier pour les groupes les plus vulnérables, tels que les seniors.
                </p>
                <div>
                    <strong>Actuellement</strong>
                    <ul>
                        <li>75 % des ménages sont connectés à Internet</li>
                        <p>Mais cela ne suffit pas à garantir que chacun maîtrise ces technologies</p>
                        <li> Environ 16 % des Réunionnais</li>
                        <p>Soit près de 105 000 personnes, éprouvent des difficultés face au numérique</p>
                        <p>Parmi eux, plus de la moitié des personnes âgées de 60 ans et plus se sentent perdues dans ce
                            monde digital.
                            Nous visons à sensibiliser et à former ces individus pour qu'ils puissent naviguer
                            efficacement dans l'univers numérique.</p>
                        <li>Bien que 47 % des Réunionnais aient effectué au moins un achat en ligne au cours
                            des douze derniers mois
                        </li>
                        <p>
                            La crainte de l'escroquerie reste un frein majeur.</p>
                        <p>Cela souligne la nécessité d'éduquer la population sur les pratiques sécurisées en ligne et
                            d'encourager une utilisation responsable d'Internet.
                            Notre association œuvre également pour valoriser les programmes publics existants, tels que
                            le Numérisak
                        </p>
                        <li>
                            Dont 88 % des foyers éligibles ont bénéficié avec une satisfaction de 90 %.
                        </li>
                        <p>
                            Cependant, il est crucial d'améliorer la notoriété du Pass Numérique, connu seulement par 17
                            % des habitants, et de répondre à la demande croissante de formations numériques, ressentie
                            par 20 % de la population.
                        </p>
                        <p>En somme, notre mission est d'accompagner chaque individu dans son parcours numérique, en
                            offrant des formations adaptées et en créant un environnement inclusif où chacun peut
                            accéder aux ressources nécessaires pour s'épanouir dans le monde digital.
                        </p>
                        <li>
                            Ensemble, nous pouvons réduire la fracture numérique et construire une communauté plus
                            connectée et solidaire.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="servicesImg">
                    <div style="position: relative; width: 100%; height: 0; padding-top: 56.2225%;
                     padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
                     border-radius: 8px; will-change: transform;">
                        <iframe loading="lazy"
                            style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                            src="https://www.canva.com/design/DAGNC6s-cuI/wq5FaKUWAnxwCaW9r6Xm-w/view?embed"
                            allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>
                    </div>

                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <h2 class="my-5 text-center">La fracture numérique</h2>
                <iframe loading="lazy"
                    style="position: relative; width: 100%; height:auto; min-height:180vh; border: none; border-radius: 8px; padding: 0; margin: 0; overflow: hidden;"
                    src="https://www.canva.com/design/DAGQVTBL-os/e191jOtOn0qjJKSIjlJ1VQ/view?embed"
                    allowfullscreen="allowfullscreen" allow="fullscreen">
                </iframe>
                <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGQVTBL-os&#x2F;e191jOtOn0qjJKSIjlJ1VQ&#x2F;view?utm_content=DAGQVTBL-os&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link"
                    target="_blank" rel="noopener">Guide ai</a> by Mario Achille
            </div>

        </div>
    </main>
</template>
<script>
export default {
    head: {
        title: 'M-1789, A Propos',
        meta: [
            {
                name: 'description',
                content:"Notre mission est d'œuvrer pour réduire cette fracture en facilitant l'accès à des services informatiques et l'utilisation des outils numériques pour tous."
            },
            {
                name: "keyword",
                content: "Fracture numérique, inclusion numérique, accès aux technologies pour tous,Faciliter l'accès aux nouvelles technologies, technologie pour tous, démocratisation du numérique"
            },
            {
                name: "author",
                content: "Achille Michael Mario"
            },
            {
                name: "copyright",
                content: "asso-mascareignes-1789.com 2024"
            },
            {
                name: "language",
                content: "fr"
            }


        ]

    }
}
</script>