<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12 bg-dark text-white p-5">
        <div class="row">
          <div class="col-md-6">
            <h1 class="text-center my-5">LE PREMIER SAVANT DE L’OCEAN INDIEN</h1>
            <p>
              JEAN-BAPTISTE LISLET-GEOFFROY
              ARS Terres Créoles/Leko
              Livre bilingue Anglais/Français en réalité augmentée
              Le premier savant de l’océan Indien est noir, il est l’exemple puissant
              de la manière dont un individu peut transformer une caractéristique,
              qui aurait pu être un obstacle, en une force pour le changement.
              Ses contributions significatives à la science, à la cartographie et à la
              géographie ont défié les normes discriminatoires du «Code Noir» de
              son époque. En démontrant ses compétences exceptionnelles et son
              engagement envers l’acquisition et la diffusion des connaissances,
              Lislet-Geoffroy a contribué à remettre en question les préjugés raciaux
              de son temps. Il a montré que la diversité et l’inclusion sont des atouts
              pour la société, et il a ouvert la voie à d’autres personnes de couleur
              pour poursuivre leurs aspirations académiques et professionnelles.<br>
              PRIX DE VENTE: 15 €</p>
          </div>
          <div class="col-md-6">
            <div class="mt-5">
              <p>« Tu m’as donné ta boue, j’en ai fait de l’or » écrivait
                Baudelaire en appendice aux Fleurs du Mal. Il est utile
                et agréable de rappeler qu’il s’exprimait autant comme
                poète des métamorphoses, que comme traducteur
                anglophile de la littérature du frisson d’Edgar A. Poe,
                ou qu’en critique des avant-gardes d’Art. Il a passé un
                temps fou dans l’atelier de Courbet ; les deux artistes,
                affairés dans l’exercice contrefactuel du monde !
                Pour Baudelaire, devenu aujourd’hui un personnage
                de papier d’un roman graphique, l’Art demeure un
                acte dément, perché entre l’instantanéité et l’éternité.
                MS MARIO SERVIABLE
                le.berger974@gmail.com
                Lekoproductionltd@gmail.com
                Editions ARS Terres Créoles
                Espace-ortho@wanadoo.fr
                22 23
                Grand Noir LE PREMIER SAVANT DE L’OCEAN INDIEN
                JEAN-BAPTISTE LISLET-GEOFFROY
                ARS Terres Créoles/Leko
                Ce livre en bilingue Anglais Français en réalité augmentée
                Le premier savant de l’océan Indien est noir, il est l’exemple puissant
                de la manière dont un individu peut transformer une caractéristique,
                qui aurait pu être un obstacle, en une force pour le changement.
                Ses contributions significatives à la science, à la cartographie et à la
                géographie ont défié les normes discriminatoires du «Code Noir» de
                son époque. En démontrant ses compétences exceptionnelles et son
                engagement envers l’acquisition et la diffusion des connaissances,
                Lislet-Geoffroy a contribué à remettre en question les préjugés raciaux
                de son temps. Il a montré que la diversité et l’inclusion sont des atou</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 bg-light p-5">
        <h2>Didier Sooben</h2>
        <small>Grand Noir</small>
        <h4>JEAN-BAPTISTE LISLET-GEOFFROY</h4>
        <h5>The Big Man</h5>
      </div>
      <div class="col-md-6 bg-light">
        <p class="p-5">A mon parrain, Mario serviable,
géographe très inspiré et inspirant
qui m’a permis de donner vie à ses mots
et sans qui ce personnage n’aurait pu exister …</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DidierPage'
};
</script>
