<template>
    <div>
        <div class="frameCanva">
            <iframe loading="lazy"
                style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                src="https://www.canva.com/design/DAGNC6s-cuI/wq5FaKUWAnxwCaW9r6Xm-w/view?embed"
                allowfullscreen="allowfullscreen" allow="fullscreen">
            </iframe>
        </div>
        <main class="container">
            <div class="row">
                <h2 class="text-center my-5" id="servicesTitle">Des solutions sur mesure pour votre réussites</h2>
                <div class="col-md-6 col-sm-12">
                    <p><strong>Améliorez votre présence en ligne grâce à nos services </strong> de développement de
                        sites Web spécialisés.
                        Nous créons des sites Web attrayants et conviviaux qui reflètent non seulement l’identité de
                        votre marque, mais qui engagent également efficacement votre public.
                        Que vous ayez besoin d’un simple site d’information ou d’une plateforme de commerce électronique
                        complexe, notre équipe utilise les dernières technologies pour garantir des performances
                        optimales et une fonctionnalité transparente. Notre approche se concentre sur vos objectifs
                        spécifiques, vous permettant de vous connecter avec votre public et de générer des conversions.
                        Associez-vous à nous pour créer un site Web qui se démarque et répond aux exigences du paysage
                        numérique actuel.</p>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="servicesImg">
                        <img src="https://firebasestorage.googleapis.com/v0/b/creation-digital/o/images%2Fthird.webp?alt=media&token=83f39d50-3d7e-4541-b00b-99b05ba7859c" alt="brain storm" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="card my-5 mb-4 rounded-3 shadow-sm">
                        <div class="card-header ">
                            <h4 class="my-0 fw-normal py-3 text-white text-center">Site-Vitrine</h4>
                        </div>
                        <div class="card-body">
                            <p class="text-center"> <i class="bi bi-book-fill text-white display-3 "></i>
                            </p>
                            <p class="card-title pricing-card-title text-center">€ 10
                                <small class="text-body-secondary fw-light">/Mois</small>
                            </p>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li><i class="bi bi-file-earmark-break-fill mr-2"></i> 5 pages</li>
                                <li>1 thème au choix</li>
                                <li>Conformité RGPD</li>
                                <li>Formulaire contact sécurisé</li>
                            </ul>
                            <a href="/contact" type="button" class="w-100 btn btn-lg btn-outline-dark">Appelez-nous pour
                                un
                                rendez-vous</a>
                        </div>
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <a class="panel-button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        <i class="bi bi-arrow-up-circle"></i>
                                    </a>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <img src="http://new-mascareigne1789.local/wp-content/uploads/2024/08/9bac9784-2eec-4d23-b012-67f116fb4258.webp"
                                            alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- card-body -->
                    </div>
                    <!-- card -->
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="accordion-item">
                        <div class="accordion-header mt-5">
                            <button class="accordion-button bg-dark p-3" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <div class="w-100 text-white p-1 text-center">Créez des sites Web attrayants adaptés aux
                                    besoins de votre association
                                    est un excellent moyen d'accroître sa visibilité et son impact. <span> <i class="bi bi-arrow-down-circle mx-2"></i></span></div>
                            </button>
                        </div>
                        <div id="collapseOne" class="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body mt-3">
                                <strong> Voici quelques raisons clés pour lesquelles un site web attrayant est
                                    important</strong>
                                <ul>
                                    <li><strong>Améliorer la visibilité :</strong> Un site internet bien conçu permet
                                        aux gens
                                        de trouver facilement votre association sur les moteurs de recherche, ce qui
                                        accroît la
                                        notoriété de votre cause.</li>
                                    <li><strong>Renforcer la crédibilité :</strong> Un site web professionnel et
                                        attrayant
                                        projette une image positive de votre association, ce qui peut renforcer la
                                        confiance du
                                        public et des partenaires potentiels.</li>
                                    <li><strong>Mobiliser les membres et les sympathisants :</strong> Votre site web
                                        peut servir
                                        de plateforme centrale pour partager des informations sur les activités de votre
                                        association, les événements à venir et les possibilités de bénévolat, ce qui
                                        peut
                                        inciter les gens à se joindre à votre cause .</li>
                                    <li><strong>Collecter des dons : </strong>Si votre association a besoin de dons pour
                                        financer ses activités, un site web peut faciliter le processus de don en ligne
                                        .</li>
                                    <li>Un site web attrayant et fonctionnel est un outil précieux pour toute
                                        association qui
                                        souhaite accroître sa visibilité, mobiliser ses partisans et atteindre ses
                                        objectifs.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <h3 class="text-center my-5">Solutions de commerce électronique</h3>
                <div class="col-md-6 col-sm-12">
                    <div class="servicesImg">
                        <img src="https://firebasestorage.googleapis.com/v0/b/creation-digital/o/images%2Ffirst.webp?alt=media&token=53ccdec4-61b9-44a9-a116-acf0cab41752" alt="brain storm" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <p class="secondServices">
                        Transformez votre association grâce à nos solutions de commerce électronique personnalisées
                        conçues pour
                        augmenter les ventes
                        et améliorer l’expérience client.
                        Nous sommes spécialisés dans la création de boutiques en ligne conviviales, faciles à parcourir
                        et
                        sécurisées pour les transactions. Nos plateformes de commerce électronique sont équipées de
                        fonctionnalités essentielles telles que des passerelles de paiement,
                        une gestion des stocks et des systèmes de support client. Grâce à notre expertise, vous pouvez
                        atteindre
                        un public plus large et maximiser votre potentiel de revenus.
                        Laissez-nous vous aider à créer une présence en ligne solide qui stimule les ventes et favorise
                        la
                        fidélité des clients,
                        positionnant ainsi votre entreprise sur la voie du succès sur le marché numérique concurrentiel.
                    </p>
                </div>
                </div>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="card my-5 mb-4 rounded-3 shadow-sm">
                        <div class="card-header ">
                            <h4 class="my-0 fw-normal py-3 text-white text-center">Site-E-Commerce</h4>
                        </div>
                        <div class="card-body">
                            <p class="text-center"> <i class="bi bi-shop text-white display-3"></i>
                            </p>
                            <p class="card-title pricing-card-title text-center">
                                <small class="text-body-secondary fw-light">A Partir de</small> € 500
                            </p>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>1 thème au choix</li>
                                <li>Paiement sécurisé</li>
                                <li>Fonctionnalité de base</li>
                                <li>Formulaire contact sécurisé</li>
                                <li>Email Personnaliser </li>
                            </ul>
                            <a href="/contact" type="button" class="w-100 btn btn-lg btn-outline-dark">Appelez-nous pour
                                un
                                rendez-vous</a>
                        </div>
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <a class="panel-button" data-bs-toggle="collapse" data-bs-target=".collapseTwo"
                                        aria-expanded="true" aria-controls="collapseTwo">
                                        <i class="bi bi-arrow-up-circle"></i>
                                    </a>
                                </div>
                                <div id="" class="panel-collapse collapse collapseTwo" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/creation-digital/o/images%2Fthird.webp?alt=media&token=83f39d50-3d7e-4541-b00b-99b05ba7859c"
                                            alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- card-body -->
                    </div>
                    <!-- card -->
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="accordion-item my-5">
                        <div class="accordion-header">
                            <button class="accordion-button bg-dark p-3" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                <div class="text-white py-1 text-center">Est-ce qu'un site e-commerce convient à votre
                                    association?. <span> <i class="bi bi-arrow-down-circle mx-2"></i></span></div>
                            </button>
                        </div>
                        <div id="collapseTwo" class="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body mt-3">
                                <p>Un site e-commerce peut être approprié pour une association dans certains cas,
                                    mais ce n'est pas forcément la solution idéale pour toutes les associations.</p>
                                <ul>
                                    <li>
                                        Si vous souhaitez vendre des produits dérivés ou des formations en ligne,
                                        alors un volet e-commerce intégré à votre site vitrine peut être intéressant
                                    </li>
                                    <li>
                                        Meilleure visibilité sur le web
                                    </li>
                                    <li>
                                        Facilité de mise à jour des informations
                                    </li>
                                    <li>
                                        Possibilité de collecter des dons
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <h3 class="text-center" id="servicesTitle">Libérez le plein potentiel de votre association</h3>
                <div class="col-md-6 col-sm-12">
                    <p class="thirdServices">
                        Grâce à nos services de gestion des médias sociaux.
                        Nous élaborons et exécutons des stratégies sur mesure qui stimulent l’engagement et créent une
                        communauté fidèle autour de votre association.
                        Notre équipe crée du contenu convaincant et gère vos comptes de médias sociaux pour garantir un
                        message cohérent et des interactions opportunes avec votre public.
                        En tirant parti des informations basées sur les données, nous optimisons votre présence en ligne
                        pour générer du trafic et augmenter les conversions.
                        Gardez une longueur d’avance sur la concurrence et communiquez plus efficacement avec votre
                        public en confiant la gestion de vos médias sociaux à nos professionnels expérimentés.
                    </p>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="servicesImg">
                        <img src="https://firebasestorage.googleapis.com/v0/b/creation-digital/o/images%2Fsecond.webp?alt=media&token=443f5572-23e2-4e18-b687-682961a5371f" alt="brain storm" class="img-fluid">

                    </div>
                </div>
                </div>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="card my-5 mb-4 rounded-3 shadow-sm">
                        <div class="card-header ">
                            <h4 class="my-0 fw-normal py-3 text-white text-center">Autre Projet</h4>
                        </div>
                        <div class="card-body">
                            <p class="text-center"> <i class="bi bi-archive text-white display-3"></i>
                            </p>
                            <p class="card-title pricing-card-title text-center">
                                <small class="text-body-secondary fw-light">A Partir de</small> € 1500
                            </p>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>Application métier</li>
                                <li>Application mobile</li>
                                <li>Fonctionnalité de base</li>
                                <li>Community management</li>
                                <li>Stratégie digitale et digitalisation de contenus</li>

                            </ul>
                            <a href="/contact" type="button" class="w-100 btn btn-lg btn-outline-dark">Appelez-nous pour
                                un
                                rendez-vous</a>
                        </div>
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <a class="panel-button" data-bs-toggle="collapse" data-bs-target=".collapsethird"
                                        aria-expanded="true" aria-controls="collapsethird">
                                        <i class="bi bi-arrow-up-circle"></i>
                                    </a>
                                </div>
                                <div id="" class="panel-collapse collapse collapsethird" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/creation-digital/o/images%2Fthird.webp?alt=media&token=83f39d50-3d7e-4541-b00b-99b05ba7859c"
                                            alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- card-body -->
                    </div>
                    <!-- card -->
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="accordion-item my-5">
                        <div class="accordion-header">
                            <button class="accordion-button bg-dark p-3" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapsethird" aria-expanded="true" aria-controls="collapsethird">
                                <div class="text-white">Vous avez un projet web ou mobile innovant et vous cherchez une
                                    équipe pour le réaliser?. <span> <i class="bi bi-arrow-down-circle mx-2"></i></span></div>
                            </button>
                        </div>
                        <div id="collapsethird" class="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body mt-3">
                                <p>Nous pouvons vous aider! Nous sommes des experts en conception et développement de
                                    solutions digitales sur mesure.</p>
                                <p> Si vous souhaitez vendre des produits dérivés ou des formations en ligne,
                                    alors un volet e-commerce intégré à votre site vitrine peut être intéressant</p>
                                <p>En nous contactant, vous pourrez bénéficier de notre expérience pour :</p>
                                <ul>
                                    <li>
                                        Définir clairement vos besoins et objectifs
                                    </li>
                                    <li>
                                        Traduire vos idées en une solution web ou mobile performante
                                    </li>
                                    <li>
                                        Bénéficier d'un accompagnement tout au long du projet
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'NosServicesComponent',
};
</script>

<style scoped>
.frameCanva {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.2225%;
    padding-bottom: 0;
    box-sizing: border-box;
    box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
    margin-top: 1.6em;
    margin-bottom: 0.9em;
    overflow: hidden;
    border-radius: 8px;
    will-change: transform;
}

.servicesImg {
    width:100%;
    height: auto;
    margin-bottom: 50px;
}

.card {
    background-color: #3333336f;
    border-radius: 8px;
    box-shadow: 0px 8px 20px #0000007a;
}

.card-header {
    background: linear-gradient(94deg, rgba(81, 112, 255, 1) 39%, rgba(146, 35, 197, 1) 60%) ! important;
    color: #ffffff !important;
}

.card-body {
    padding: 0px 15px;
    color: white;
    min-height: 461px;
    max-height: 600px;
    text-align: center;
}
</style>