<template>
   <iframe 
   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17726.06928108157!2d55.29391153559715!3d-20.934555721828318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2182888a843b7097%3A0x5fae8eaaa5fb78e3!2sAv.%20Du%2014%20Juillet%201789%2C%20Le%20Port%2097420%2C%20La%20R%C3%A9union!5e0!3m2!1sfr!2sfr!4v1727325206670!5m2!1sfr!2sfr" 
   id="map" 
   style="border:0;"
   allowfullscreen=""
   loading="lazy" referrerpolicy="no-referrer-when-downgrade">
   </iframe>
</template>
<script>

export default {
    setup() {
      
    },
       
};
</script>

<style scoped>
#map {
    height: 400px;
    width: 100%;
    margin-bottom: 50px;
}
</style>