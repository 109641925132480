<template>
    <div class="overlay">
        <div class="p-5 bg-primary text-white rounded" id="serviesPage">
        </div>
    </div>
    <main class="container">
        <h1 class="text-center my-5" id="servicesTitle">Notre Méthode de Travail</h1>
        <div class="row">
            <div class="col-md-6">
                <div class="card"> 
                    <div class="card-body alert alert-sucess my-5 mb-4 rounded-3 shadow-sm">               
                <p>
                    Chez Asso-Mascareignes-1789, nous croyons que chaque projet digital commence par une vision.
                    Nous sommes ici pour transformer votre rêve de site Internet en réalité,
                    en vous accompagnant à chaque étape du processus. </p>
                    <p>Nous allons procéder pour concrétiser votre projet, depuis le premier rendez-vous jusqu'à la maintenance de votre site.
                </p>
                <p class="">
                    Notre méthode de travail est basée sur une approche collaborative et personnalisée.
                    Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins et leurs
                    objectifs.</p>
                    <p>Nous élaborons ensuite une stratégie personnalisée qui prend en compte les spécificités de chaque
                    projet.
                    Notre équipe est composée de professionnels expérimentés et passionnés par leur métier.
                    Nous sommes à l'écoute de nos clients et nous nous engageons à leur fournir des solutions innovantes
                    et de haute qualité.
                </p>
                <div>
                    <ul>
                        <li><strong>Analyse des besoins</strong>: Nous commençons par une analyse approfondie de vos
                            besoins et de vos objectifs.
                            Nous identifions les défis que vous rencontrez et les opportunités que vous souhaitez saisir.
                        </li>
                        <li><strong>Élaboration d'une stratégie</strong>: Nous élaborons une stratégie personnalisée qui
                            prend en compte vos besoins et vos objectifs.
                            Nous définissons les actions à mettre en œuvre et les indicateurs de performance à suivre.
                        </li>
                        <li><strong>Mise en œuvre</strong>: Nous mettons en œuvre la stratégie définie en utilisant les
                            outils et les technologies les plus adaptés.
                            Nous suivons l'avancement du projet et nous nous assurons que les objectifs sont atteints.
                        </li>
                        <li><strong>Suivi et évaluation</strong>: Nous suivons l'avancement du projet et nous évaluons
                            régulièrement les résultats obtenus.
                            Nous ajustons la stratégie si nécessaire pour garantir que les objectifs sont atteints.
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card my-5 mb-4 rounded-3 shadow-sm" id="firstCardMTD">                   
                    <div class="card-header ">
                        <h4 class="my-0 fw-normal py-3 bg-dark text-white text-center">Premier Rendez-vous</h4>
                      </div>
                        <div class="card-body togglePageMDT">
                            <h4>Écoute et Recueil de Vos Idées</h4>
                            <p>
                                Tout commence par un premier rendez-vous où nous prendrons le temps de comprendre vos besoins, vos objectifs et vos idées.
                                Ce moment d'échange est crucial pour poser les bases de votre projet.
                                Nous discuterons des fonctionnalités souhaitées, du public cible, et de l’image que vous souhaitez véhiculer à travers votre site.
                                Notre équipe s'engage à écouter attentivement vos attentes afin de créer un site qui vous ressemble.
                            </p>
                        </div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <a class="panel-button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        <i class="bi bi-arrow-up-circle"></i>
                                    </a>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <img src=""
                                            alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                        </div>
                </div>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card my-5 mb-4 rounded-3 shadow-sm" id="firstCardMTD">                   
                    <div class="card-header ">
                        <h4 class="my-0 fw-normal py-3 bg-dark text-white text-center"> Initialisation </h4>
                      </div>
                        <div class="card-body togglePageMDT">
                            <h5>Élaboration d’un Cahier des Charges</h5>
                            <p>
                                Suite à notre rencontre,
                                nous élaborerons un cahier des charges détaillé qui servira de feuille de route pour le développement de votre site.
                                Ce document précisera les fonctionnalités, le design, les délais et le budget prévisionnel.
                                Nous vous soumettrons ce cahier des charges pour validation avant de passer à l’étape suivante.
                            </p>
                        </div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <a class="panel-button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        <i class="bi bi-arrow-up-circle"></i>
                                    </a>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <img src=""
                                            alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                        </div>
                </div>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card my-5 mb-4 rounded-3 shadow-sm" id="firstCardMTD">                   
                    <div class="card-header ">
                        <h4 class="my-0 fw-normal py-3 bg-dark text-white text-center"> Lancement </h4>
                      </div>
                        <div class="card-body togglePageMDT">
                            <h4>Création d’un Planning de Projet</h4>
                            <p>
                                Une fois le cahier des charges validé, nous établirons un planning de projet clair avec des étapes définies.
                                Cela inclut la répartition des tâches au sein de notre équipe et la définition des délais pour chaque phase du développement.
                                Vous serez régulièrement informé des avancées et impliqué dans les décisions clés.
                            </p>
                        </div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <a class="panel-button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        <i class="bi bi-arrow-up-circle"></i>
                                    </a>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <img src=""
                                            alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                        </div>
                </div>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card my-5 mb-4 rounded-3 shadow-sm firstCardMTD">                   
                    <div class="card-header ">
                        <h4 class="my-0 fw-normal py-3 bg-dark text-white text-center">Conception</h4>
                      </div>
                        <div class="card-body togglePageMDT">
                            <h5>Design et Développement</h5>
                            <p>
                                C'est maintenant le moment où votre rêve prend forme ! Notre équipe de designers créera une maquette visuelle de votre site,
                                en prenant en compte l'identité visuelle que vous souhaitez transmettre.
                                Une fois la maquette validée, nos développeurs commenceront à coder 
                                le site en utilisant les technologies les plus adaptées à vos besoins (comme Vue.js ou WordPress).
                            </p>
                        </div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <a class="panel-button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        <i class="bi bi-arrow-up-circle"></i>
                                    </a>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <img src=""
                                            alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                        </div>
                </div>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card my-5 mb-4 rounded-3 shadow-sm firstCardMTD">                   
                    <div class="card-header ">
                        <h4 class="my-0 fw-normal py-3 bg-dark text-white text-center">Production </h4>
                      </div>
                        <div class="card-body togglePageMDT">
                            <h4>Tests et Lancement</h4>
                            <p>
                                TAvant le lancement officiel, nous procéderons à une série de tests rigoureux pour garantir 
                                que tout fonctionne parfaitement. Cela inclut des tests de fonctionnalité,
                                d’ergonomie et de compatibilité sur différents appareils et navigateurs.
                                Une fois que nous avons validé que tout est conforme à vos attentes,
                                 nous procéderons au lancement officiel de votre site.
                            </p>
                        </div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <a class="panel-button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        <i class="bi bi-arrow-up-circle"></i>
                                    </a>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <img src=""
                                            alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                        </div>
                </div>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card my-5 mb-4 rounded-3 shadow-sm firstCardMTD">                   
                    <div class="card-header ">
                        <h4 class="my-0 fw-normal py-3 bg-dark text-white text-center"> Maintenance  </h4>
                      </div>
                        <div class="card-body togglePageMDT">
                            <h5>Suivi et Améliorations Continues</h5>
                            <p>
                                Notre engagement ne s'arrête pas au lancement. Nous offrons 
                                un service de maintenance pour assurer le bon fonctionnement de votre site à long terme.
                                Cela comprend la mise à jour régulière du contenu, la sécurité du site, 
                                ainsi que l’ajout éventuel de nouvelles fonctionnalités selon l’évolution de vos besoins.
                            </p>
                        </div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <a class="panel-button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        <i class="bi bi-arrow-up-circle"></i>
                                    </a>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <img src=""
                                            alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                        </div>
                </div>
                </div>
                <div class="card my-5 mb-4 rounded-3 shadow-sm">
                    <div class="card-body bg-light text-dark">
                    <p>Ensemble, nous allons concrétiser votre rêve numérique en créant 
                        un site Internet qui non seulement répond à vos attentes mais qui dépasse
                        également vos ambitions. Chez Asso-Mascareignes-1789, nous sommes passionnés par la création
                        d'expériences digitales qui engagent et inspirent.
                       Prêt à commencer cette aventure avec nous ? Contactez-nous dès aujourd'hui !
                    </p>
                </div>
                </div>
            </div>
        </main>
    </template>
<script>
export default {

}
</script>
<style scoped>
.firstCardMTD{
    margin-top: 70px; 
}
.togglePageMDT{
height:350px;
overflow: hidden;
overflow-wrap: unset-content;
}
.panel-button {
    z-index: 500;
}
</style>